<template>
  <v-container>
    <div class="container-global">
      <v-form
        ref="formResponderEncuesta"
        v-model="valid"
        lazy-validation
        @submit.prevent="guardaRespuesta()"
      >
        <h3 class="mb-6">{{ respuestaEncuesta.title }}</h3>
        <div
          class="container-respuesta"
          v-for="(item, index) in respuestaEncuesta.preguntas"
          :key="index"
        >
          <h3 class="text-body-2 title-respuesta mb-3">
            {{ item.texto }}
          </h3>
          <div class="list-respuesta">
            <div class="item-respuesta">
              <input
                type="radio"
                v-bind:id="'radioRespuestaExcelente' + index"
                v-bind:name="'respuestaRadio' + index"
                class="input-radio"
                value="excelente"
                v-model="item.respuesta"
                required
              />
              <label
                v-bind:for="'radioRespuestaExcelente' + index"
                class="label-selector-radio"
              >
                <div class="container-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="45"
                    height="45"
                    viewBox="0 0 45 45"
                  >
                    <defs>
                      <clipPath id="716iiknz4a">
                        <path
                          data-name="Rectángulo 134"
                          style="fill: #070707"
                          d="M0 0h45v45H0z"
                        />
                      </clipPath>
                    </defs>
                    <g data-name="Grupo 73">
                      <g
                        data-name="Grupo 72"
                        style="clip-path: url(#716iiknz4a)"
                      >
                        <path
                          data-name="Trazado 23"
                          d="M22.5 45A22.5 22.5 0 1 1 45 22.5 22.526 22.526 0 0 1 22.5 45m0-43.581A21.081 21.081 0 1 0 43.581 22.5 21.1 21.1 0 0 0 22.5 1.419"
                          style="fill: #070707"
                        />
                        <path
                          data-name="Trazado 24"
                          d="M36.976 61.6a9.514 9.514 0 0 1-9.5-9.5.71.71 0 0 1 .71-.71h17.583a.71.71 0 0 1 .71.71 9.514 9.514 0 0 1-9.5 9.5m-8.056-8.8a8.084 8.084 0 0 0 16.105 0z"
                          transform="translate(-14.475 -27.075)"
                          style="fill: #070707"
                        />
                        <path
                          data-name="Trazado 25"
                          d="M27.193 38.63A1.635 1.635 0 1 1 25.558 37a1.635 1.635 0 0 1 1.635 1.635"
                          transform="translate(-12.605 -19.493)"
                          style="fill: #070707"
                        />
                        <path
                          data-name="Trazado 26"
                          d="M67.553 38.63A1.635 1.635 0 1 1 65.918 37a1.635 1.635 0 0 1 1.635 1.635"
                          transform="translate(-33.871 -19.493)"
                          style="fill: #070707"
                        />
                      </g>
                    </g>
                  </svg>
                </div>
                <p class="text-caption title-icon">Excelente</p>
              </label>
            </div>
            <div class="item-respuesta">
              <input
                type="radio"
                v-bind:id="'radioRespuestaBueno' + index"
                v-bind:name="'respuestaRadio' + index"
                class="input-radio"
                value="bueno"
                v-model="item.respuesta"
                required
              />
              <label
                v-bind:for="'radioRespuestaBueno' + index"
                class="label-selector-radio"
              >
                <div class="container-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="45"
                    height="45"
                    viewBox="0 0 45 45"
                  >
                    <defs>
                      <clipPath id="3oan4tmtea">
                        <path
                          data-name="Rectángulo 138"
                          style="fill: none"
                          d="M0 0h45v45H0z"
                        />
                      </clipPath>
                    </defs>
                    <g data-name="Grupo 78">
                      <g
                        data-name="Grupo 77"
                        style="clip-path: url(#3oan4tmtea)"
                      >
                        <path
                          data-name="Trazado 27"
                          d="M22.5 45A22.5 22.5 0 1 1 45 22.5 22.526 22.526 0 0 1 22.5 45m0-43.581A21.081 21.081 0 1 0 43.581 22.5 21.1 21.1 0 0 0 22.5 1.419"
                        />
                        <path
                          data-name="Trazado 28"
                          d="M22.5 34.525a9.514 9.514 0 0 1-9.5-9.5.71.71 0 1 1 1.419 0 8.084 8.084 0 1 0 16.167 0 .71.71 0 1 1 1.419 0 9.514 9.514 0 0 1-9.5 9.5"
                        />
                        <path
                          data-name="Trazado 29"
                          d="M14.588 19.137a1.635 1.635 0 1 1-1.635-1.635 1.635 1.635 0 0 1 1.635 1.635"
                        />
                        <path
                          data-name="Trazado 30"
                          d="M33.682 19.137a1.635 1.635 0 1 1-1.635-1.635 1.635 1.635 0 0 1 1.635 1.635"
                        />
                      </g>
                    </g>
                  </svg>
                </div>
                <p class="text-caption title-icon">Bueno</p>
              </label>
            </div>
            <div class="item-respuesta">
              <input
                type="radio"
                v-bind:id="'radioRespuestaRegular' + index"
                v-bind:name="'respuestaRadio' + index"
                class="input-radio"
                value="regular"
                v-model="item.respuesta"
                required
              />
              <label
                v-bind:for="'radioRespuestaRegular' + index"
                class="label-selector-radio"
              >
                <div class="container-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="45"
                    height="45"
                    viewBox="0 0 45 45"
                  >
                    <defs>
                      <clipPath id="2kdjpqfpoa">
                        <path
                          data-name="Rectángulo 139"
                          style="fill: none"
                          d="M0 0h45v45H0z"
                        />
                      </clipPath>
                    </defs>
                    <g data-name="Grupo 80">
                      <g
                        data-name="Grupo 79"
                        style="clip-path: url(#2kdjpqfpoa)"
                      >
                        <path
                          data-name="Trazado 31"
                          d="M22.5 45A22.5 22.5 0 1 1 45 22.5 22.526 22.526 0 0 1 22.5 45m0-43.581A21.081 21.081 0 1 0 43.581 22.5 21.1 21.1 0 0 0 22.5 1.419"
                        />
                        <path
                          data-name="Trazado 32"
                          d="M14.588 19.137a1.635 1.635 0 1 1-1.635-1.635 1.635 1.635 0 0 1 1.635 1.635"
                        />
                        <path
                          data-name="Trazado 33"
                          d="M33.682 19.137a1.635 1.635 0 1 1-1.635-1.635 1.635 1.635 0 0 1 1.635 1.635"
                        />
                        <path
                          data-name="Trazado 34"
                          d="M31.279 31.174H13.721a.71.71 0 1 1 0-1.419h17.558a.71.71 0 1 1 0 1.419"
                        />
                      </g>
                    </g>
                  </svg>
                </div>
                <p class="text-caption title-icon">Regular</p>
              </label>
            </div>
            <div class="item-respuesta">
              <input
                type="radio"
                v-bind:id="'radioRespuestaMalo' + index"
                v-bind:name="'respuestaRadio' + index"
                class="input-radio"
                value="malo"
                v-model="item.respuesta"
                required
              />
              <label
                v-bind:for="'radioRespuestaMalo' + index"
                class="label-selector-radio"
              >
                <div class="container-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="45"
                    height="45"
                    viewBox="0 0 45 45"
                  >
                    <defs>
                      <clipPath id="jiuh9hzwoa">
                        <path
                          data-name="Rectángulo 140"
                          style="fill: none"
                          d="M0 0h45v45H0z"
                        />
                      </clipPath>
                    </defs>
                    <g data-name="Grupo 82">
                      <g
                        data-name="Grupo 81"
                        style="clip-path: url(#jiuh9hzwoa)"
                      >
                        <path
                          data-name="Trazado 35"
                          d="M22.5 45A22.5 22.5 0 1 1 45 22.5 22.526 22.526 0 0 1 22.5 45m0-43.581A21.081 21.081 0 1 0 43.581 22.5 21.1 21.1 0 0 0 22.5 1.419"
                        />
                        <path
                          data-name="Trazado 36"
                          d="M31.294 34.525a.709.709 0 0 1-.71-.71 8.084 8.084 0 1 0-16.168 0 .71.71 0 0 1-1.419 0 9.503 9.503 0 1 1 19.006 0 .71.71 0 0 1-.71.71"
                        />
                        <path
                          data-name="Trazado 37"
                          d="M14.588 19.137a1.635 1.635 0 1 1-1.635-1.635 1.635 1.635 0 0 1 1.635 1.635"
                        />
                        <path
                          data-name="Trazado 38"
                          d="M33.682 19.137a1.635 1.635 0 1 1-1.635-1.635 1.635 1.635 0 0 1 1.635 1.635"
                        />
                      </g>
                    </g>
                  </svg>
                </div>
                <p class="text-caption title-icon">Malo</p>
              </label>
            </div>
          </div>
        </div>
        <p class="text-caption title-icon">
          ¿Deseas dejarnos tu correo para recibir promociones?
        </p>
        <v-text-field
          color="black"
          v-model="correo"
          :rules="emailRules"
          label="Escribe tu correo electrónico"
          outlined
        ></v-text-field>
        <div class="d-flex justify-center">
          <v-btn
            color="black"
            class="white--text pr-8 pl-8 btn-margin"
            :disabled="!valid"
            :loading="loading"
            type="submit"
            >Enviar</v-btn
          >
        </div>
      </v-form>
    </div>
  </v-container>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      id: this.$route.params.id,
      valid: false,
      loading: false,
      correo: "",
      emailRules: [
        (v) =>
          (v.length === 0 && !v) ||
          /.+@.+\..+/.test(v) ||
          "El correo es incorrecto",
      ],
    };
  },
  created() {
    this.obtieneEncuestaData();
  },
  computed: {
    ...mapState("moduloEncuestas", ["respuestaEncuesta"]),
  },
  methods: {
    ...mapActions(["obtieneAparienciaMenu"]),
    ...mapActions("moduloEncuestas", [
      "obtieneResponderEncuesta",
      "guardaRespuestasEncuesta",
    ]),
    async obtieneEncuestaData(){
      const result = await this.obtieneResponderEncuesta(this.id);
      if(result.res){
        this.obtieneMenuData(this.respuestaEncuesta.slug);
      }else{
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
          timerProgressBar: true,
          title: result.error,
        });
      }
    },
    async guardaRespuesta() {
      //se validan los campos
      if (!this.$refs.formResponderEncuesta.validate()) return;
      var validateRespuesta = this.respuestaEncuesta.preguntas.filter(
        (pregunta) => pregunta.respuesta == ""
      );
      if (validateRespuesta.length > 0) {
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
          timerProgressBar: true,
          title: "Contesta todas las preguntas",
        });
        return;
      }
      let arrayRespuestas = this.respuestaEncuesta.preguntas.map(
        (pregunta) => ({
          tituloPregunta: pregunta.texto,
          respuesta: pregunta.respuesta,
        })
      );
      const paramSend = {
        id: this.respuestaEncuesta.id,
        respuestas: arrayRespuestas,
        correo: this.correo,
      };
      const result = await this.guardaRespuestasEncuesta(paramSend);
      if (result.res) {
        this.reset();
        this.correo = "";
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "success",
          timerProgressBar: true,
          title: "Gracias por responder nuestra encuesta.",
        });
      } else {
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
          timerProgressBar: true,
          title: result.error,
        });
      }
    },
    obtieneMenuData(slug) {
      this.obtieneAparienciaMenu(slug);
    },
    reset() {
      this.respuestaEncuesta.preguntas.forEach((element) => {
        element.respuesta = "";
      });
    },
  },
};
</script>
<style lang="scss">
.container-respuesta {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  .title-respuesta {
    color: #525252;
  }
}

.list-respuesta {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  .item-respuesta {
    width: 48%;
    margin-bottom: 8px;
    .input-radio:checked + .label-selector-radio {
      background-color: #000;
      .title-icon {
        color: #ffffff;
      }
      .container-icon svg {
        fill: #ffffff !important;
      }
      .container-icon svg path {
        fill: #ffffff !important;
      }
    }
    .input-radio {
      appearance: none;
      display: none;
    }
    // .input-radio:invalid + .label-selector-radio {
    //    border: solid 0.5px red;
    // }
    .label-selector-radio {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100px;
      border-radius: 2.5px;
      border: solid 0.5px #e2e2e2;
      cursor: pointer;
      .container-icon {
        width: 30px;
        height: 30px;
        svg {
          width: 100%;
          height: 100%;
        }
        margin-bottom: 12px;
      }
      .title-icon {
        color: #151515;
        margin-bottom: 0;
      }
    }
  }
}
</style>